/* src/components/About.css */
.about-section {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 40px;
  margin-left: 15px;
  margin-right: 15px;
}

.about-image-container {
  flex: 0 0 40%; /* Adjust the width of the image container */
  margin-right: 20px; /* Add some space between the image and the text */
}

.about-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-height: 770px;
}

.about-text {
  flex: 1;
  overflow-y: auto; /* Add vertical scroll to the text area */
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 32px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.about-text h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.about-text p {
  font-size: 1rem;
  color: #333;
  line-height: 1.6;
  margin-bottom: 20px;
}

/* Responsive adjustments */
@media (max-width: 900px) {
  .about-section {
    flex-direction: column;
    align-items: center;
  }

  .about-image-container {
    width: 80%;
    margin-bottom: 20px; /* Add some space below the image */
  }

  .about-text {
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .about-image {
    max-height: 700px;
  }
}
