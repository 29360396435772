/* src/components/FullGallery.css */
.full-gallery {
  padding: 0;
  margin: 0;
}

.header-image-container {
  width: 100%;
  height: 200px; /* Adjust this value as necessary */
  overflow: hidden;
  position: relative;
}

.header-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.header-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.gallery-content {
  padding: 20px;
  padding-left: 40px;
  padding-right: 40px;
  text-align: center;
}

.full-gallery h2 {
  position: relative;
  z-index: 2;
  margin-top: -10px;
}

.full-gallery-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  position: relative;
  z-index: 2;
}

.full-gallery-item {
  flex: 1 1 calc(33.333% - 10px);
  margin-bottom: 10px;
}

.full-gallery-item img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
