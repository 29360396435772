/* src/components/ServicesCards.css */
.services-cards {
  display: flex;
  justify-content: space-between;
  padding: 0 10%;
  background-color: #f9f9f9;
  position: relative;
  z-index: 2; /* Ensure it is above the header overlay */
  gap: 60px;
  padding-bottom: 60px;
}

.card {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 30%;
  padding: 20px;
  margin-top: -50px; /* Negative margin to overlap header */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  transition: all 0.3s ease;
}

.card-content {
  text-align: center;
}

.card h3 {
  font-size: calc(1.2rem + 0.5vw);
  margin-bottom: 10px;
  text-align: center;
}

.card ul {
  list-style-type: none; /* Remove default list styling */
  padding: 0;
  margin: 0;
}

.card li {
  font-size: calc(0.9rem + 0.5vw); /* Adjust font size for list items */
  color: #666;
  text-align: left;
  line-height: 1.5;
  margin-bottom: 10px;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .card {
    padding: 15px;
  }

  .card h3 {
    font-size: calc(1rem + 0.5vw);
  }

  .card li {
    font-size: calc(0.85rem + 0.4vw);
  }
}

@media (max-width: 1100px) {
  .services-cards {
    padding: 0 1%;
    padding-bottom: 45px;
    gap: 45px;
  }

  .card {
    width: 30%;
    padding: 15px;
  }

  .card h3 {
    font-size: calc(1rem + 0.4vw);
  }

  .card li {
    font-size: calc(0.8rem + 0.35vw);
  }
}

@media (max-width: 900px) {
  .services-cards {
    flex-direction: column;
    align-items: center;
  }

  .card {
    width: 90%;
    padding: 10px;
    margin-bottom: 25px;
  }

  .card h3 {
    font-size: calc(1.1rem + 0.4vw);
  }

  .card li {
    font-size: calc(0.95rem + 0.3vw);
  }
}

@media (max-width: 800px) {
  .card {
    width: 90%; /* Adjust the width to control card size */
    padding: 10px;
  }

  .card h3 {
    font-size: calc(1rem + 0.4vw);
  }

  .card li {
    font-size: calc(0.9rem + 0.3vw);
  }
}

@media (max-width: 600px) {
  .card {
    width: 90%; /* Adjust the width to control card size */
    padding: 10px;
    margin: -10px 0; /* Add margin to separate the cards */
  }

  .card h3 {
    font-size: calc(1rem + 0.3vw);
  }

  .card li {
    font-size: calc(0.85rem + 0.25vw);
  }
}
