/* src/components/Header.css */
.header-container {
  position: relative;
  width: 100%;
  height: 65vh; /* Adjust height for initial view */
  min-height: 300px; /* Ensure a minimum height */
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.header-content {
  text-align: center;
  color: white;
  padding: 20px;
  z-index: 1;
}

.header-logo {
  width: 150px;
  height: auto;
  margin-bottom: 20px;
}

.header-title {
  font-size: 2.5rem;
  margin: 0;
}

.header-subtitle {
  font-size: 1.25rem;
  margin: 10px 0 20px 0;
}

.header-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.header-button {
  background-color: #333;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.header-button:hover {
  background-color: #555;
}

.header-service-text {
  font-size: 2rem;
  margin-top: 20px; /* Increase this value to move the text lower */
  color: white;
  position: relative;
  top: 12vw;
}

/* Media queries for responsiveness */
@media (max-width: 767px) {
  .header-service-text {
    font-size: 1.25rem;
    margin-top: 10px;
    top: 7vw;
  }
}

@media (min-width: 768px) {
  .header-container {
    height: 65vh; /* Adjust height for medium screens */
    min-height: 600px; /* Ensure a minimum height */
  }

  .header-title {
    font-size: 3rem;
  }

  .header-subtitle {
    font-size: 1.5rem;
  }

  .header-buttons {
    gap: 30px;
  }

  .header-button {
    padding: 15px 30px;
  }
  .header-service-text {
    top: 11vw;
  }
}

@media (min-width: 1200px) {
  .header-container {
    height: 65vh; /* Adjust height for large screens */
    min-height: 700px; /* Ensure a minimum height */
  }
}

@media (min-width: 1440px) {
  .header-container {
    height: 100vh; /* Adjust height for extra large screens */
    min-height: 600px; /* Ensure a minimum height */
    background-position: top center;
    background-size: cover;
    margin-top: -75px;
  }
  .header-service-text {
    top: 12vw;
  }
}
