/* src/components/FederationLogos.css */
.federation-logos-section {
  text-align: center;
  padding: 40px 0;
  background-color: #f9f9f9;
}

.federation-logos-section h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.logos-container {
  display: flex;
  justify-content: center;
  gap: 40px;
  flex-wrap: wrap;
}

.logo-card {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px; /* Adjust the size as needed */
  height: 150px; /* Ensure it maintains a square aspect ratio */
  transition: all 0.3s ease;
}

.federation-logo {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .logo-card {
    width: 100px;
    height: 100px;
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .logo-card {
    width: 80px;
    height: 80px;
    padding: 10px;
  }
}
