/* src/components/Footer.css */
.footer {
  width: 100%;
  position: relative;
  overflow: hidden;
  text-align: center;
  padding-top: 40px;
  height: 175px; /* Ensure the footer has a fixed height */
}

.footer-image-container {
  width: 100%;
  height: 100%; /* Match the height of the footer */
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0; /* Send the image behind the content */
}

.footer-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Pseudo-element for overlay */
.footer-image-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7); /* Adjust the opacity here */
  z-index: 1; /* Above the image but below the content */
}

.footer-content {
  position: relative;
  z-index: 2; /* Ensure content is above the overlay */
  padding: 10px 20px;
  border-radius: 8px;
  display: inline-block;
  margin-top: 20px; /* Adjust to ensure it is above the footer image */
}

.footer-icons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.footer-icon {
  color: #333;
  transition: color 0.3s ease;
}

.footer-icon:hover {
  color: #555;
}

.footer-content p {
  margin: 5px 0;
  font-size: 14px;
  color: #666;
}
