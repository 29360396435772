/* src/components/Navbar.css */

.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: rgba(51, 51, 51, 0.8); /* Semi-transparent background */
  overflow: hidden;
  z-index: 1000;
  margin: 0;
}

.navbar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.navbar li {
  display: inline;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  cursor: pointer;
}

.navbar li:hover {
  background-color: #ddd;
  color: black;
}

.navbar a {
  color: white;
  text-decoration: none;
}

.navbar a:visited {
  color: white;
}

.navbar-logo img {
  height: 50px; /* Adjust size as needed */
  filter: invert(1); /* Invert the color to white */
}

/* Ensure content below the navbar is not hidden */
body {
  padding-top: 60px; /* Adjust based on the navbar height */
}

h1 {
  margin-top: 60px; /* Add margin to prevent content from being hidden behind the navbar */
}

/* Responsive Styles */

@media (max-width: 560px) {
  .navbar ul {
    flex-direction: row; /* Ensure the links stay in a row */
  }

  .navbar li {
    padding: 8px 10px; /* Reduce padding */
    font-size: 11px; /* Reduce font size */
  }

  .navbar-logo img {
    height: 25px; /* Reduce logo size */
  }
}
