/* src/App.css */

body,
html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  position: relative;
}

body {
  background-color: white;
}

.background-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7); /* Adjust the opacity here */
  z-index: -1; /* Ensure the overlay is behind all other content */
}

.App {
  position: relative;
  z-index: 1; /* Ensure the content is above the background overlay */
}

.container {
  width: 100%;
  padding: 0;
  margin: 0;
}

.header-image-container {
  width: 100%;
  height: 200px; /* Adjust this value as necessary */
  overflow: hidden;
  position: relative;
  top: 0;
  left: 0;
  margin-top: -20px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.header-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.header-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

@media (max-width: 764px) {
  .header-image-container {
    height: 150px;
  }
}
