/* src/components/TestimonialsCarousel.css */

.carousel-container {
  display: flex;
  justify-content: center;
  padding: 0 10%;
  background-color: #f9f9f9;
  position: relative;
  z-index: 2;
  gap: 60px;
  padding-bottom: 60px;
}

.testimonials-carousel {
  max-width: 1200px; /* Limit the maximum width of the carousel */
  margin: 0 auto; /* Center the carousel */
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  position: relative; /* Ensure positioning for arrows */
  overflow: hidden; /* Hide overflow for rounded corners */
}

/* Hide the status indicator (1 of 5, etc.) */
.carousel .carousel-status {
  display: none;
}

.carousel .slide {
  display: flex;
  flex-direction: column; /* Ensure strong is above the dots */
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 40px;
  background: none;
  height: 300px; /* Reduce the height */
}

.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  opacity: 0.5;
  transition: all 0.25s ease-in;
  width: 30px;
  height: 30px;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  z-index: 2;
}

.carousel .control-arrow:hover {
  opacity: 1;
}

.carousel .control-prev.control-arrow {
  left: 10px; /* Position the left arrow inside the box */
}

.carousel .control-prev.control-arrow:before {
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right: 8px solid #000;
  content: "";
}

.carousel .control-next.control-arrow {
  right: 10px; /* Position the right arrow inside the box */
}

.carousel .control-next.control-arrow:before {
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 8px solid #000;
  content: "";
}

.carousel .control-dots {
  margin-top: 20px; /* Ensure dots are below the text */
}

.carousel .control-dots .dot {
  background: #000;
  width: 10px;
  height: 10px;
  margin: 0 3px;
  opacity: 0.5;
  transition: opacity 0.25s ease-in;
}

.carousel .control-dots .dot.selected,
.carousel .control-dots .dot:hover {
  opacity: 1;
}

.testimonials-carousel .carousel .slide div {
  padding: 20px; /* Add padding to ensure buffer on all sides */
}

.testimonials-carousel .carousel .slide p {
  text-align: center;
  margin: 0;
  font-size: 1.2rem; /* Responsive text size */
}

.testimonials-carousel .carousel .slide strong {
  display: block;
  margin-top: 10px;
  font-size: 1rem; /* Responsive text size */
}

/* Responsive adjustments */

/* Adjustments for widths between 461px and 560px */
@media (min-width: 461px) and (max-width: 560px) {
  .testimonials-carousel {
    padding: 15px;
  }

  .carousel .slide {
    padding: 15px;
    height: 250px;
  }

  .carousel .control-arrow {
    width: 25px;
    height: 25px;
  }

  .carousel .control-prev.control-arrow:before,
  .carousel .control-next.control-arrow:before {
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
  }

  .carousel .control-prev.control-arrow:before {
    border-right: 6px solid #000;
  }

  .carousel .control-next.control-arrow:before {
    border-left: 6px solid #000;
  }

  .carousel .control-dots .dot {
    width: 8px;
    height: 8px;
  }

  .testimonials-carousel .carousel .slide p {
    font-size: 0.9rem; /* Adjust based on your needs */
  }

  .testimonials-carousel .carousel .slide strong {
    font-size: 0.8rem; /* Adjust based on your needs */
  }
}

/* Adjustments for widths 540px and below */
@media (max-width: 560px) {
  .testimonials-carousel {
    padding: 15px;
  }

  .carousel .slide {
    padding: 15px;
    height: 250px;
  }

  .carousel .control-arrow {
    width: 25px;
    height: 25px;
  }

  .carousel .control-prev.control-arrow:before,
  .carousel .control-next.control-arrow:before {
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
  }

  .carousel .control-prev.control-arrow:before {
    border-right: 6px solid #000;
  }

  .carousel .control-next.control-arrow:before {
    border-left: 6px solid #000;
  }

  .carousel .control-dots .dot {
    width: 8px;
    height: 8px;
  }

  .testimonials-carousel .carousel .slide p {
    font-size: 0.5rem; /* Adjust based on your needs */
  }

  .testimonials-carousel .carousel .slide strong {
    font-size: 0.65rem; /* Adjust based on your needs */
  }
}

/* Adjustments for widths between 561px and 869px */
@media (min-width: 561px) and (max-width: 869px) {
  .testimonials-carousel {
    padding: 20px;
  }

  .carousel .slide {
    padding: 20px;
    height: 300px;
  }

  .carousel .control-arrow {
    width: 30px;
    height: 30px;
  }

  .carousel .control-prev.control-arrow:before,
  .carousel .control-next.control-arrow:before {
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
  }

  .carousel .control-prev.control-arrow:before {
    border-right: 8px solid #000;
  }

  .carousel .control-next.control-arrow:before {
    border-left: 8px solid #000;
  }

  .carousel .control-dots .dot {
    width: 10px;
    height: 10px;
  }

  .testimonials-carousel .carousel .slide p {
    font-size: 0.7rem; /* Adjust based on your needs */
  }

  .testimonials-carousel .carousel .slide strong {
    font-size: 0.9rem; /* Adjust based on your needs */
  }
}

/* Adjustments for widths between 870px and 1023px */
@media (min-width: 870px) and (max-width: 1023px) {
  .testimonials-carousel {
    padding: 20px;
  }

  .carousel .slide {
    padding: 20px;
    height: 300px;
  }

  .carousel .control-arrow {
    width: 30px;
    height: 30px;
  }

  .carousel .control-prev.control-arrow:before,
  .carousel .control-next.control-arrow:before {
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
  }

  .carousel .control-prev.control-arrow:before {
    border-right: 8px solid #000;
  }

  .carousel .control-next.control-arrow:before {
    border-left: 8px solid #000;
  }

  .carousel .control-dots .dot {
    width: 10px;
    height: 10px;
  }

  .testimonials-carousel .carousel .slide p {
    font-size: 1rem; /* Adjust based on your needs */
  }

  .testimonials-carousel .carousel .slide strong {
    font-size: 0.9rem; /* Adjust based on your needs */
  }
}

/* Adjustments for widths 1024px and above */
@media (min-width: 1024px) {
  .testimonials-carousel {
    padding: 30px;
  }

  .carousel .slide {
    padding: 30px;
    height: 400px; /* Adjust the height */
  }

  .carousel .control-arrow {
    width: 35px;
    height: 35px;
  }

  .carousel .control-prev.control-arrow:before,
  .carousel .control-next.control-arrow:before {
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
  }

  .carousel .control-prev.control-arrow:before {
    border-right: 10px solid #000;
  }

  .carousel .control-next.control-arrow:before {
    border-left: 10px solid #000;
  }

  .carousel .control-dots .dot {
    width: 12px;
    height: 12px;
  }

  .testimonials-carousel .carousel .slide p {
    font-size: 1.2rem; /* Adjust based on your needs */
  }

  .testimonials-carousel .carousel .slide strong {
    font-size: 1.2rem; /* Adjust based on your needs */
  }
}

/* Adjustments for widths 1440px and above */
@media (min-width: 1440px) {
  .testimonials-carousel {
    padding: 40px;
  }

  .carousel .slide {
    padding: 40px;
    height: 500px; /* Adjust the height */
  }

  .carousel .control-arrow {
    width: 40px;
    height: 40px;
  }

  .carousel .control-prev.control-arrow:before,
  .carousel .control-next.control-arrow:before {
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
  }

  .carousel .control-prev.control-arrow:before {
    border-right: 12px solid #000;
  }

  .carousel .control-next.control-arrow:before {
    border-left: 12px solid #000;
  }

  .carousel .control-dots .dot {
    width: 14px;
    height: 14px;
  }

  .testimonials-carousel .carousel .slide p {
    font-size: 1.6rem; /* Adjust based on your needs */
  }

  .testimonials-carousel .carousel .slide strong {
    font-size: 1.5rem; /* Adjust based on your needs */
  }
}
