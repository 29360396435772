body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

.meet-recaps {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.search-bar {
  width: 100%;
  max-width: 600px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 20px;
}

.instagram-post {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  max-width: 900px;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  margin-bottom: 20px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-left: auto;
  margin-right: auto;
}

.post-container {
  flex: 1;
  max-height: 450px;
  overflow: hidden;
}

.caption-container {
  flex: 1;
  padding: 10px;
  font-size: 14px;
  color: #333;
  max-height: 450px;
  overflow-y: auto;
  background-color: #f9f9f9;
  border-left: 2px solid #ddd;
}

.pagination {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.pagination.top {
  margin-bottom: 10px;
}

.pagination.bottom {
  margin-top: 10px;
}

.pagination button {
  background-color: #333;
  color: #fff;
  border: none;
  padding: 8px 12px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.pagination button.active {
  background-color: #555;
}

.pagination button:hover {
  background-color: #555;
}

/* Grid layout for large screens */
@media (min-width: 1200px) {
  .posts-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 40px;
    width: 100%;
    max-width: 1440px;
  }

  .instagram-post {
    max-width: none; /* Remove max-width to allow flexible grid items */
    margin: 0; /* Remove individual margins, use grid-gap instead */
  }

  .instagram-post:nth-child(2n + 1) {
    margin-right: 20px; /* Add margin only to odd items to separate columns */
  }
}

/* Ensure single column layout for smaller screens */
@media (max-width: 1199px) {
  .posts-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

/* Adjust layout for screens below 730px width */
@media (max-width: 730px) {
  .instagram-post {
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center the content horizontally */
    max-width: 100%; /* Ensure the container doesn't exceed the screen width */
  }

  .post-container {
    margin-right: 0;
    margin-bottom: 10px; /* Add some space between the post and caption */
    width: 100%; /* Ensure the post container takes full width */
  }

  .caption-container {
    border-left: none;
    border-top: 2px solid #ddd;
    width: 100%; /* Ensure the caption container takes full width */
  }
}
