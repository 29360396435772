/* src/components/Contact.css */
.contact-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
  background-color: #f9f9f9;
}

.form-container {
  width: 100%;
  max-width: 800px;
  margin-bottom: 40px;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contact-icons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.contact-icon {
  color: #333;
  transition: color 0.3s ease;
}

.contact-icon:hover {
  color: #555;
}
