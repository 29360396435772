/* Gallery Styles */
.gallery {
  margin-top: 0px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.gallery-item {
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 30%; /* Default to 3 images per row */
}

.responsive-image {
  width: 100%;
  height: auto;
  max-height: 500px;
  border-radius: 10px;
  object-fit: contain; /* Ensure images cover the container uniformly without cutting off */
}

/* Media queries for responsive design */
@media (max-width: 1024px) {
  .gallery-item {
    flex-basis: 45%; /* Two images per row on medium screens */
  }
}

@media (max-width: 600px) {
  .gallery-item {
    flex-basis: 90%; /* One image per row on small screens */
  }
}
